import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import LazyImage from 'src/components/Common/LazyImage';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import useMediaQuery from 'src/lib/Hooks/useMediaQuery';
import { YouTubePlayer } from 'youtube-player/dist/types';
import ThumbnailsItem from './components/ThumbnailsItem';
import { MultiVideoItemsProps, MultiVideoProps } from './types';

const SRSelectedLabel = ({ text = '' }: { text: string | undefined }) => (
  <span className="sr-only" aria-live="polite" role="status" aria-atomic="true">
    {`${text} Video Selected`}
  </span>
);

const MultiVideoComponent = ({ items }: MultiVideoProps) => {
  // grab data for first video for use in the "poster" overlay placeholder
  const { text: posterText, poster, video } = items[0];

  const [player, setPlayer] = useState<null | YouTubePlayer>(null);
  const [videoId, setVideoId] = useState(video);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const isMd = useMediaQuery('md');

  const playerVars = {
    autoplay: 0,
    cc_load_policy: 1,
    controls: 1,
    rel: 0,
  } as const;

  const handleClick = (item: MultiVideoItemsProps) => {
    const { video } = item;
    const page = window?.location?.pathname || '';
    sendAttribution(page, player);
    if (player) {
      player.loadVideoById(video);
    }
    setVideoId(video);
    setHasPlayed(true);
  };

  const sendAttribution = (page: string, player: YouTubePlayer | null) => {
    if (player) {
      track.video({
        id: videoId,
        target: player,
        videoType: 'multi',
        page,
      });
    }
  };

  // removes the ability to tab onto youtube player while covered by thumbnail.
  useEffect(() => {
    if (!document) return;

    const youtubePlayer = document.getElementById('youtubePlayer');

    hasPlayed
      ? youtubePlayer?.removeAttribute('tabindex')
      : youtubePlayer?.setAttribute('tabindex', '-1');
  }, [hasPlayed]);

  useEffect(() => {
    const page = window?.location?.pathname || '';
    return () => {
      sendAttribution(page, player);
    };
  }, [player]);

  return (
    <section className="container-4xl pb-32 px-16 xl:pb-48 xl:px-24 w-full">
      <div className="relative">
        {/* Placeholder gray background that sits beneath the other elements, it is seen briefly when changing videos and the YouTube player is loading. */}
        <div className="relative overflow-auto max-w-2xl mx-auto">
          <div className="bg-gray rounded-md aspect-16/9 z-hide" />
        </div>
        {/* YouTube Video player */}
        <div className="absolute inset-0 max-w-2xl mx-auto">
          {videoEnded && (
            <>
              <button
                className="absolute bg-black w-full h-full z-overlay"
                onClick={() => {
                  player?.playVideo();
                  setVideoEnded(false);
                }}
                tabIndex={0}
              >
                <div className="relative flex items-center justify-center bg-black bg-opacity-40 rounded-md">
                  <SvgLoader name="Play" color="text-white" className="absolute" size={64} />
                </div>
              </button>
            </>
          )}
          <YouTube
            id="youtubePlayer"
            videoId={videoId}
            onReady={event => setPlayer(event.target)}
            opts={{ playerVars, width: '', height: '' }}
            title={`Video: ${posterText?.value}`}
            className="relative overflow-auto max-w-2xl mx-auto"
            iframeClassName="w-full aspect-16/9 rounded-md"
            onEnd={() => {
              setVideoEnded(true);
            }}
          />
        </div>
        {/* Poster screen */}
        {!hasPlayed && (
          <div className="absolute inset-0">
            <div className="relative overflow-auto max-w-2xl mx-auto">
              <div className="w-full aspect-16/9 rounded-md">
                {/* Poster Image */}
                <LazyImage
                  {...poster}
                  alt=""
                  className="js-poster-thumbnail absolute w-full aspect-16/9 rounded-md"
                />
                {/* Poster Overlay - play button and title */}
                <div className="bg-black bg-opacity-40 items-center flex h-full justify-center w-full rounded-md">
                  <button
                    className="js-poster-button text-white flex flex-col uppercase px-16 md:px-96 w-full h-full relative justify-center"
                    onClick={() => handleClick(items[0])}
                  >
                    <SvgLoader
                      name="Play"
                      color="text-current"
                      size={isMd ? 76 : 48}
                      className="self-center"
                      aria-hidden={true}
                      focusable={false}
                    />
                    {posterText?.value && (
                      <Text
                        field={posterText}
                        tag="span"
                        className="js-poster-text block text-md md:text-lg mt-8 md:mt-16 truncate md:overflow-auto md:whitespace-normal w-full"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Thumbnail Navigation */}
      <ul
        className={`${
          player ? 'flex' : 'invisible'
        } flex-no-wrap overflow-x-auto md:overflow-visible md:flex-wrap justify-start md:justify-center mt-16 mx-auto`}
      >
        {items.map((item, index) => (
          <ThumbnailsItem
            {...item}
            key={index}
            clickHandler={() => {
              handleClick(item);
              setSelectedItemIndex(index);
              setVideoEnded(false);
            }}
          />
        ))}
        {selectedItemIndex > -1 && <SRSelectedLabel text={items[selectedItemIndex]?.text?.value} />}
      </ul>
    </section>
  );
};

const MultiVideo = (props: MultiVideoProps) => {
  if (!props.items?.length) {
    return null;
  }

  return <MultiVideoComponent {...props} />;
};

export default MultiVideo;
