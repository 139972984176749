import Composition from 'src/lib/HOC/Composition';
import MultiVideo from './index';
import { MultiVideoProps } from './types';

const { compositionFunction, component } = Composition(MultiVideo)(({ fields }) => {
  const items = fields?.items?.reduce(
    (acc: MultiVideoProps['items'], curr: typeof fields) => [
      ...acc,
      {
        id: curr.Id,
        poster: curr.fields['Large Image'],
        text: curr.fields.Title,
        thumb: curr.fields['Thumbnail Image'],
        video: curr.fields?.['Video link']?.value?.url?.split('/embed/')[1],
      },
    ],
    []
  );

  return { items };
});

export { compositionFunction };
export default component;
